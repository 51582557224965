<template>
    <div class="commodity-detail">
        <app-header v-if="title" :title="title" :isShowBack="true"></app-header>
        <div>
            <el-form ref="myForm" :model="myForm" :rules="rules">
                <el-form-item label-width="120px" label="供应商名称" prop="supplierId">
                    <el-select v-model="myForm.supplierId" placeholder="请选择供应商" filterable :disabled="notEdit || notEdit2">
                        <el-option v-for="item in supplierList" :key="item.id" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="120px" label="商品品类" prop="type">
                    <el-select v-model="myForm.type" placeholder="请选择商品品类" filterable :disabled="notEdit || notEdit2">
                        <el-option v-for="item in typeList" :key="item.id" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="120px" label="商品名称" prop="name">
                    <el-input v-model.trim="myForm.name" maxlength="32" :disabled="notEdit" placeholder="请填写商品名称"></el-input>
                </el-form-item>
                <el-form-item label-width="120px" label="成本价（元）" prop="costPrice">
                    <el-input v-model="myForm.costPrice" :disabled="notEdit" placeholder="请填写成本价（元）"></el-input>
                </el-form-item>
                <el-form-item label-width="120px" label="经销价（元）" prop="supplyPrice">
                    <el-input v-model="myForm.supplyPrice" :disabled="notEdit" placeholder="请填写经销价（元）"></el-input>
                    <div class="tips">针对分销商品，商品按经销差价计算收益，商品品类对应的分佣比例不生效。</div>
                </el-form-item>
                <el-form-item label-width="120px" label="售价范围（元）">
                    <el-col :span="6">
                        <el-input v-model="myForm.lowPrice" :disabled="notEdit" placeholder="最低销售价（元）"></el-input>
                    </el-col>
                    <el-col class="line" :span="2">——</el-col>
                    <el-col :span="6">
                        <el-input v-model="myForm.hightPrice" :disabled="notEdit" placeholder="最高销售价（元）"></el-input>
                    </el-col>
                    <el-col :span="20">
                      <div class="tips">当设置了售价范围，则商家设置销售价必须在售价范围内。</div>
                    </el-col>
                </el-form-item>
                <el-form-item label-width="120px" label="商品详细描述" prop="detail">
                    <el-input type="textarea" show-word-limit  :rows="5" v-model="myForm.detail" maxlength="500" :disabled="notEdit" placeholder="请填写商品详细描述"></el-input>
                </el-form-item>
                <el-form-item label-width="120px" label="商品图片" prop="pictureUrlList">
                  <el-upload v-if="!notEdit" accept="image/*" list-type="picture-card" :action="picUrl"
                      :file-list="myForm.pictureUrlList" :limit="6" name="file"
                      :on-success="onSuccessByFormUploadImg()" :on-remove="onHandleRemove()"
                      :before-remove="onBeforeRemoveByUploadImg()" :on-preview="onPreviewByUploadImg"
                      :on-exceed="onExceed" :disabled="notEdit" :class="{'upload-disabled': notEdit}">
                      <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="img-list" v-if="notEdit">
                      <img v-for="it in myForm.picList" :key="it.src" :src="it.src" @click="showBig(it.src)" />
                  </div>
                  <div class="img-upload-alt">支持上传1-6张图片，支持png、jpg格式*第一张图片为主照片，供手机端首页展示</div>
                </el-form-item>
                <div class="submitBox" v-show="!notEdit">
                  <el-button style="width: 180px" type="primary" @click="submit()">提交</el-button>
              </div>
            </el-form>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="50%">
            <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
    </div>
  </template>
  <script>
    let priceVali = (rule, value, callback) => {
      var reg = /(^[0-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,4}$)/
      if (!reg.test(value)) {
        callback(
          new Error("请输入数字，且小数点后最多保留4位")
        );
      } else {
        callback();
      }
    };
    let validItemsName = (rule, value, callback) => {
      if(!value) {
        callback(new Error("请输入商品名称"));
      } else {
        //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
        let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
        if (!reg.test(value)) {
            callback(new Error("商品名称格式不对或超过32个字符"));
        } else {
            callback();
        }
      }
    };
    let validCode = (rule, value, callback) => {
      //支持输入大小写字母，数字，限制1~16位字符
      let reg = /^[A-z0-9]{1,32}$/;
      if (!reg.test(value)) {
        callback(new Error("商品编号格式不对"));
      } else {
        callback();
      }
    };
    import cabinetUtil from "@utils/cabinetUtil.js"
    export default {
      data() {
        let _this = this;
        return {
            title: '发布商品',
            notEdit: false,
            supplierList: [],
            typeList: [],
            picUrl: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
            dialogVisible: false,
            dialogImageUrl: '',
            notEdit2: false,
            myForm: {
                supplierId: '',
                type: '',
                name: '',
                costPrice: '',
                supplyPrice: '',
                lowPrice: '',
                hightPrice: '',
                detail: '',
                pictureUrlList: [],
                picList: [],
            },
            rules: {
                supplierId: [{
                    required: true,
                    message: "请选择供应商名称",
                    trigger: ['change']
                }],
                type: [{
                    required: true,
                    message: "请选择商品品类",
                    trigger: ['change']
                }],
                name: [{
                    required: true,
                    validator: validItemsName,
                    trigger: ['change']
                }],
                costPrice: [{
                    required: true,
                    message: "请填写成本价（元）",
                    trigger: ['change']
                }],
                supplyPrice: [{
                    required: true,
                    message: "请填写经销价（元）",
                    trigger: ['change']
                }],
                pictureUrlList: [{
                    required: true,
                    message: "请选择图片",
                    trigger: ['change']
                }]
            }
        };
      },
      activated() {
        console.log("commodity-detail activated!!");
        this.title = ["发布商品", "编辑商品", "商品详情"][{
          add: 0,
          edit: 1,
          show: 2
        } [this.$route.params.type]];
        this.supplierList = []
        this.$nextTick(() => {
            if (this.$refs["myForm"]) {
                this.$refs["myForm"].resetFields();
            }
        })
        this.myForm =  {
            supplierId: '',
            type: '',
            name: '',
            costPrice: '',
            supplyPrice: '',
            lowPrice: '',
            hightPrice: '',
            detail: '',
            pictureUrlList: [],
            picList: [],
        }
        this.getCategory();
        this.getMerchantList();
        if (this.$route.params.type == "edit" || this.$route.params.type == "show") {
            this.getDetail();
        }
        if (this.$route.params.type == "show") {
          this.notEdit = true
        } else {
          this.notEdit = false
        }
        if (this.$route.params.type == "edit" || this.$route.params.type == "show") {
          this.notEdit2 = true
        } else {
          this.notEdit2 = false
        }
      },
      methods: {
        submit() {
          this.$refs["myForm"].validate((valid) => {
            if (!valid) {
                return;
            } else {
                if(Number(this.myForm.supplyPrice) < Number(this.myForm.costPrice)) {
                  return this.$message({
                        message: '经销价不得低于成本价',
                        type: 'warning'
                    })
                }
                let dto = {
                  supplierId: this.myForm.supplierId,
                  type: this.myForm.type,
                  name: this.myForm.name,
                  costPrice: this.myForm.costPrice,
                  supplyPrice: this.myForm.supplyPrice,
                  lowPrice: this.myForm.lowPrice,
                  hightPrice: this.myForm.hightPrice,
                  detail: this.myForm.detail,
                  // pictureUrlList: this.myForm.pictureUrlList,
                  publishMerType: 3,
                  state: 1,//默认传1
                }
                this.supplierList.forEach(item => {
                  if (this.myForm.supplierId == item.value) {
                    dto.supplierName = item.label
                  }
                })
                dto.picList = [];
                this.myForm.pictureUrlList.forEach((data, index) => {
                    dto.picList.push(data.url);
                });
                let url = "mall-service/items/add";
                let message = "添加商品成功";
                if (this.$route.params.type == "edit") {
                  dto.id = this.$route.params.id;
                  url = "mall-service/items/update";
                  message = "编辑商品成功";
                }
                this.post(url, dto,{isUseResponse: true}).then(res => {
                    if(res.data.code == 0) {
                      this.$message({
                          showClose: true,
                          message: message,
                          type: "success"
                      });
                      this.$back();
                    }
                })

              }
          })
        },
        getDetail(){
          this.get("mall-service/items/detail/" + this.$route.params.id).then(res => {
            console.log(res,'res---');
            this.myForm.supplierId = res.supplierId;
            this.myForm.type = res.type;
            this.myForm.name = res.name;
            this.myForm.costPrice = res.costPrice ? Number(res.costPrice).toFixed(2):'';
            this.myForm.supplyPrice = res.supplyPrice ? Number(res.supplyPrice).toFixed(2):'';
            this.myForm.lowPrice = res.lowPrice ? Number(res.lowPrice).toFixed(2):'';
            this.myForm.hightPrice = res.hightPrice ? Number(res.hightPrice).toFixed(2):'';
            this.myForm.detail = res.detail;
            this.myForm.pictureUrlList = [];
            this.myForm.picList = [];
            res.picList.forEach((item, index) => {
                this.myForm.picList.push({
                    src: item
                });
                this.myForm.pictureUrlList.push({
                    name: `${index}.jpg`,
                    url: item
                });
            });
          })
        },
        getCategory() {
          let dto = {
            pageNum: 1,
            pageSize: 9999,
            isDisable: 1,
            state: 1,//默认传1
            type: "3"
          };
          this.post("mall-service/category/page/query", dto, {
            isUseResponse: true
          }).then(res => {
            var array = res.data.list;
            array.forEach(item => {
              item.value = item.id;
              item.label = item.name;
            })
            this.typeList = array;  
          })
        },
        getMerchantList() {
          this.post('/mall-service/merchant/v1/query/list',{roleType: 2}).then(res => {
            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
            this.supplierList = res
          })
        },
        handleRemove(file, fileList) {
          console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
        },
        //配合图片上传确认item
        onPreviewByUploadImg(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        //表单类型图片上传回调
        onSuccessByFormUploadImg() {
            return (response, file, fileList) => {
                this.myForm.pictureUrlList.push({
                    url: response.data.picPath
                });
            }
        },
        // 图片删除
        onHandleRemove() {
            return (file, fileList) => {
                this.myForm.pictureUrlList = fileList;
            };
        },
        //文件上传数量超过限制
        onExceed() {
            this.$message({
                message: "文件上传数量超过限制，请删除后重新上传",
                type: "warning"
            });
        },
        //图片上传的图片删除
        onBeforeRemoveByUploadImg() {
            return (file, fileList) => {
                return new Promise((resolve, reject) => {
                    let _this = this;
                    let is = true;
                    if (is) {
                        this.$confirm("确认删除此图片吗？", "提示", {
                                confirmButtonText: "确定",
                                cancelButtonText: "取消",
                                type: "warning"
                            })
                            .then(() => {
                                console.log('fileList:', fileList)
                                resolve();

                                return true
                            })
                            .catch(function () {
                                reject();
                            });
                    } else {
                        resolve();
                    }
                })
            }
        },
        //放大图片
        showBig(val) {
            console.log('val:', val)
            this.dialogImageUrl = val;
            this.dialogVisible = true;
        },
      }
    };
  </script>
  <style lang="scss">
    .commodity-detail {
      .el-form {
        margin: 0 auto;
        max-width: 1100px;

        .line{
          text-align: center;
        }
        
        .tips{
          font-size: 12px;
          color: orange;
        }

        .img-upload-alt {
          font-size: 12px;
          color: #999;
        }
      }
    }
    .submitBox {
        text-align: center;
        padding-bottom: 20px;
    }
    .upload-edit {
      .el-upload {
        display: none !important; 
      }
      .el-icon-delete {
        display: none !important; 
      }
    }
    .img-list {
        white-space: nowrap;
        overflow-x: auto;

        img {
            margin-right: 10px;
            height: 160px;
            cursor: pointer;
        }
    }

    .img {
        img {
            max-width: 500px;
            cursor: pointer;
        }
    }
  </style>