var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commodity-detail" },
    [
      _vm.title
        ? _c("app-header", { attrs: { title: _vm.title, isShowBack: true } })
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "myForm", attrs: { model: _vm.myForm, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "供应商名称",
                    prop: "supplierId"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择供应商",
                        filterable: "",
                        disabled: _vm.notEdit || _vm.notEdit2
                      },
                      model: {
                        value: _vm.myForm.supplierId,
                        callback: function($$v) {
                          _vm.$set(_vm.myForm, "supplierId", $$v)
                        },
                        expression: "myForm.supplierId"
                      }
                    },
                    _vm._l(_vm.supplierList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "商品品类",
                    prop: "type"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择商品品类",
                        filterable: "",
                        disabled: _vm.notEdit || _vm.notEdit2
                      },
                      model: {
                        value: _vm.myForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.myForm, "type", $$v)
                        },
                        expression: "myForm.type"
                      }
                    },
                    _vm._l(_vm.typeList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "商品名称",
                    prop: "name"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "32",
                      disabled: _vm.notEdit,
                      placeholder: "请填写商品名称"
                    },
                    model: {
                      value: _vm.myForm.name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.myForm,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "myForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "成本价（元）",
                    prop: "costPrice"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.notEdit,
                      placeholder: "请填写成本价（元）"
                    },
                    model: {
                      value: _vm.myForm.costPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "costPrice", $$v)
                      },
                      expression: "myForm.costPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "经销价（元）",
                    prop: "supplyPrice"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.notEdit,
                      placeholder: "请填写经销价（元）"
                    },
                    model: {
                      value: _vm.myForm.supplyPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "supplyPrice", $$v)
                      },
                      expression: "myForm.supplyPrice"
                    }
                  }),
                  _c("div", { staticClass: "tips" }, [
                    _vm._v(
                      "针对分销商品，商品按经销差价计算收益，商品品类对应的分佣比例不生效。"
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "120px", label: "售价范围（元）" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.notEdit,
                          placeholder: "最低销售价（元）"
                        },
                        model: {
                          value: _vm.myForm.lowPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.myForm, "lowPrice", $$v)
                          },
                          expression: "myForm.lowPrice"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-col", { staticClass: "line", attrs: { span: 2 } }, [
                    _vm._v("——")
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.notEdit,
                          placeholder: "最高销售价（元）"
                        },
                        model: {
                          value: _vm.myForm.hightPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.myForm, "hightPrice", $$v)
                          },
                          expression: "myForm.hightPrice"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 20 } }, [
                    _c("div", { staticClass: "tips" }, [
                      _vm._v(
                        "当设置了售价范围，则商家设置销售价必须在售价范围内。"
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "商品详细描述",
                    prop: "detail"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      "show-word-limit": "",
                      rows: 5,
                      maxlength: "500",
                      disabled: _vm.notEdit,
                      placeholder: "请填写商品详细描述"
                    },
                    model: {
                      value: _vm.myForm.detail,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "detail", $$v)
                      },
                      expression: "myForm.detail"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "商品图片",
                    prop: "pictureUrlList"
                  }
                },
                [
                  !_vm.notEdit
                    ? _c(
                        "el-upload",
                        {
                          class: { "upload-disabled": _vm.notEdit },
                          attrs: {
                            accept: "image/*",
                            "list-type": "picture-card",
                            action: _vm.picUrl,
                            "file-list": _vm.myForm.pictureUrlList,
                            limit: 6,
                            name: "file",
                            "on-success": _vm.onSuccessByFormUploadImg(),
                            "on-remove": _vm.onHandleRemove(),
                            "before-remove": _vm.onBeforeRemoveByUploadImg(),
                            "on-preview": _vm.onPreviewByUploadImg,
                            "on-exceed": _vm.onExceed,
                            disabled: _vm.notEdit
                          }
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      )
                    : _vm._e(),
                  _vm.notEdit
                    ? _c(
                        "div",
                        { staticClass: "img-list" },
                        _vm._l(_vm.myForm.picList, function(it) {
                          return _c("img", {
                            key: it.src,
                            attrs: { src: it.src },
                            on: {
                              click: function($event) {
                                _vm.showBig(it.src)
                              }
                            }
                          })
                        }),
                        0
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "img-upload-alt" }, [
                    _vm._v(
                      "支持上传1-6张图片，支持png、jpg格式*第一张图片为主照片，供手机端首页展示"
                    )
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.notEdit,
                      expression: "!notEdit"
                    }
                  ],
                  staticClass: "submitBox"
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.submit()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "50%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }